
<template>
  <div id="dashboard-analytics">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <div v-show="!isLoading">
      <div class="vx-row">
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <statistics-card-line
            v-if="subscribersGained.analyticsData"
            icon="UsersIcon"
            :statistic="subscribersGained.analyticsData.subscribers | k_formatter"
            statisticTitle="Subscribers Gained"
            :chartData="subscribersGained.series"
            type="area" />
        </div>
        <div v-if="$acl.check('superadmin')" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <statistics-card-line
            v-if="revenueGenerated.analyticsData"
            icon="DollarSignIcon"
            :statistic="revenueGenerated.analyticsData.revenue | k_formatter"
            statisticTitle="Revenue Generated"
            :chartData="revenueGenerated.series"
            color="success"
            type="area" />
        </div>
        <div v-if="$acl.check('admin')" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
          <statistics-card-line
            v-if="ordersRecevied.analyticsData"
            icon="ShoppingBagIcon"
            :statistic="ordersRecevied.analyticsData.orders | k_formatter"
            statisticTitle="Orders Received"
            :chartData="ordersRecevied.series"
            color="warning"
            type="area" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2 mb-base">
          <vx-card v-if="chartsReady">
            <div class="vx-row flex-col-reverse md:flex-col-reverse sm:flex-row lg:flex-row">
              <div class="vx-col w-full md:w-full sm:w-1/2 lg:w-1/2 xl:w-1/3 flex flex-col justify-between" v-if="chart4.income">
                <div>
                  <h2 class="mb-1 font-bold">{{ chart4.income }}</h2>
                  <span class="font-medium">Orders Total income</span>
                </div>
                <vs-button icon-pack="feather" icon="icon-chevrons-right" icon-after class="shadow-md w-full lg:mt-0 mt-4" @click="$router.push('/orders')">{{ $t('all') }} {{ $t('orders') }}</vs-button>
              </div>
              <div class="vx-col w-full md:w-full sm:w-1/2 lg:w-1/2 xl:w-2/3 flex flex-col lg:mb-0 md:mb-base sm:mb-0 mb-base">
                <change-time-duration-dropdown :current-duration="chart4CurrentTitle" @timeDurationChanged="change4Duration" class="self-end" />
                <vue-apex-charts type="bar" height="200" :options="chart4Options" :series="chart4Options.series" v-if="chart4Options.series" />
              </div>
            </div>
            <vs-divider class="my-5"></vs-divider>
            <div class="vx-row">
              <div class="vx-col w-1/2 mb-2"><p>All: {{ chart4.all }}</p>
                <vs-progress class="block mt-1" :percent="(chart4.all / chart4.all) * 100" color="success"></vs-progress>
              </div>
              <div class="vx-col w-1/2 mb-3"><p>Pending: {{ chart4.pending }}</p>
                <vs-progress class="block mt-1" :percent="(chart4.pending / chart4.all) * 100" color="warning"></vs-progress>
              </div>
              <div class="vx-col w-1/2 mb-3"><p>Accepted: {{ chart4.accepted }}</p>
                <vs-progress class="block mt-1" :percent="(chart4.accepted / chart4.all) * 100" color="primary"></vs-progress>
              </div>
              <div class="vx-col w-1/2 mb-3"><p>Rejected: {{ chart4.rejected }}</p>
                <vs-progress class="block mt-1" :percent="(chart4.rejected / chart4.all) * 100" color="danger"></vs-progress>
              </div>
              <div class="vx-col w-1/2 mb-3"><p>Delivered: {{ chart4.delivered }}</p>
                <vs-progress class="block mt-1" :percent="(chart4.delivered / chart4.all) * 100" color="success"></vs-progress>
              </div>
              <div class="vx-col w-1/2 mb-2"><p>Not delivered: {{ chart4.not_delivered }}</p>
                <vs-progress class="block mt-1" :percent="(chart4.not_delivered / chart4.all) * 100" color="danger"></vs-progress>
              </div>
            </div>
          </vx-card>
        </div>
        <!-- <div class="vx-col w-full md:w-1/2 mb-base">
          <vx-card v-if="chartsReady">
            <div class="vx-row flex-col-reverse md:flex-col-reverse sm:flex-row lg:flex-row">
              <div class="vx-col w-full md:w-full sm:w-1/2 lg:w-1/2 xl:w-1/3 flex flex-col justify-between" v-if="chart5.income">
                <div>
                  <h2 class="mb-1 font-bold">{{ chart5.income }}</h2>
                  <span class="font-medium">Bulk Orders Total income</span>
                </div>
                <vs-button icon-pack="feather" icon="icon-chevrons-right" icon-after class="shadow-md w-full lg:mt-0 mt-4" @click="$router.push('/orders')">{{ $t('all') }} {{ $t('orders') }}</vs-button>
              </div>
              <div class="vx-col w-full md:w-full sm:w-1/2 lg:w-1/2 xl:w-2/3 flex flex-col lg:mb-0 md:mb-base sm:mb-0 mb-base">
                <change-time-duration-dropdown :current-duration="chart5CurrentTitle" @timeDurationChanged="change5Duration" class="self-end" />
                <vue-apex-charts type="bar" height="200" :options="chart5Options" :series="chart5Options.series" v-if="chart5Options.series" />
              </div>
            </div>
            <vs-divider class="my-5"></vs-divider>
            <div class="vx-row">
              <div class="vx-col w-1/2 mb-2"><p>All: {{ chart5.all }}</p>
                <vs-progress class="block mt-1" :percent="(chart5.all / chart5.all) * 100" color="success"></vs-progress>
              </div>
              <div class="vx-col w-1/2 mb-3"><p>Pending: {{ chart5.pending }}</p>
                <vs-progress class="block mt-1" :percent="(chart5.pending / chart5.all) * 100" color="warning"></vs-progress>
              </div>
              <div class="vx-col w-1/2 mb-3"><p>Accepted: {{ chart5.accepted }}</p>
                <vs-progress class="block mt-1" :percent="(chart5.accepted / chart5.all) * 100" color="primary"></vs-progress>
              </div>
              <div class="vx-col w-1/2 mb-3"><p>Rejected: {{ chart5.rejected }}</p>
                <vs-progress class="block mt-1" :percent="(chart5.rejected / chart5.all) * 100" color="danger"></vs-progress>
              </div>
              <div class="vx-col w-1/2 mb-3"><p>Delivered: {{ chart5.delivered }}</p>
                <vs-progress class="block mt-1" :percent="(chart5.delivered / chart5.all) * 100" color="success"></vs-progress>
              </div>
              <div class="vx-col w-1/2 mb-2"><p>Not delivered: {{ chart5.not_delivered }}</p>
                <vs-progress class="block mt-1" :percent="(chart5.not_delivered / chart5.all) * 100" color="danger"></vs-progress>
              </div>
            </div>
          </vx-card>
        </div> -->
        <!-- <div class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
          <vx-card title="Support Tracker">
            <template slot="actions">
              <change-time-duration-dropdown />
            </template>
            <div slot="no-body" v-if="supportTracker.analyticsData">
              <div class="vx-row text-center">
                <div class="vx-col w-full lg:w-1/5 md:w-full sm:w-1/5 flex flex-col justify-between mb-4 lg:order-first md:order-last sm:order-first order-last">
                  <div class="lg:ml-6 lg:mt-6 md:mt-0 md:ml-0 sm:ml-6 sm:mt-6">
                    <h1 class="font-bold text-5xl">{{ supportTracker.analyticsData.openTickets }}</h1>
                    <small>Tickets</small>
                  </div>
                </div>
                <div class="vx-col w-full lg:w-4/5 md:w-full sm:w-4/5 justify-center mx-auto lg:mt-0 md:mt-6 sm:mt-0 mt-6">
                  <vue-apex-charts type="radialBar" height="385" :options="analyticsData.supportTrackerRadialBar.chartOptions" :series="supportTracker.series" />
                </div>
              </div>
              <div class="flex flex-row justify-between px-8 pb-4 mt-4">
                <p class="text-center" v-for="(val, key) in supportTracker.analyticsData.meta" :key="key">
                  <span class="block">{{ key }}</span>
                  <span class="text-2xl font-semibold">{{ val }}</span>
                </p>
              </div>
            </div>
          </vx-card>
        </div> -->
          <!-- <div class="vx-col w-full lg:w-1/2 mb-base">
            <vx-card title="Orders"> -->
              <!-- <template slot="actions">
                <change-time-duration-dropdown />
              </template> -->
              <!-- <div slot="no-body">
                <vue-apex-charts type="radialBar" height="393" :options="analyticsData.productOrdersRadialBar.chartOptions" :series="analyticsData.productOrdersRadialBar.series" />
              </div> -->
              <!-- <ul>
                <li v-for="orderData in productsOrder.analyticsData" :key="orderData.orderType" class="flex mb-3 justify-between">
                  <span class="flex items-center">
                    <span class="inline-block h-4 w-4 rounded-full mr-2 bg-white border-3 border-solid" :class="`border-${orderData.color}`"></span>
                    <span class="font-semibold">{{ orderData.orderType }}</span>
                  </span>
                  <span>{{ orderData.counts }}</span>
                </li>
              </ul> -->
            <!-- </vx-card>
          </div> -->
      </div>
      <div class="vx-row">

          <!-- CARD 7: Sales Stats -->
          <!-- <div class="vx-col w-full lg:w-1/3 mb-base">
            <vx-card title="Sales Stats" subtitle="Last 6 Months">
              <template slot="actions">
                <feather-icon icon="MoreVerticalIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
              </template>
              <div class="flex">
                <span class="flex items-center"><div class="h-3 w-3 rounded-full mr-1 bg-primary"></div><span>Sales</span></span>
                <span class="flex items-center ml-4"><div class="h-3 w-3 rounded-full mr-1 bg-success"></div><span>Visits</span></span>
              </div>
              <div slot="no-body-bottom">
                <vue-apex-charts type="radar" height="385" :options="analyticsData.statisticsRadar.chartOptions" :series="salesRadar.series" />
              </div>
            </vx-card>
          </div> -->
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vx-card title="Dispatched Orders">
            <div slot="no-body" class="mt-4">
              <vs-table :data="dispatchedOrders" class="table-dark-inverted">
                <template slot="thead">
                  <vs-th>{{ $t('fields.code') }}</vs-th>
                  <vs-th>{{ $t('fields.status') }}</vs-th>
                  <vs-th>{{ $t('fields.name') }}</vs-th>
                  <vs-th>{{ $t('fields.address') | capitalize }}</vs-th>
                  <vs-th>{{ $t('fields.price') }}</vs-th>
                  <vs-th>{{ $t('props.createdAt') }}</vs-th>
                  <vs-th>{{ $t('delivery') }}</vs-th>
                  <vs-th>{{ $t('fields.actions') }}</vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].code">
                      <span>#{{data[indextr].code}}</span>
                    </vs-td>
                    <vs-td :data="data[indextr].status">
                      <vs-chip :color="'warning'" class="product-order-status">{{ $t(`orderPage.${data[indextr].status}`) }}</vs-chip>
                    </vs-td>
                    <vs-td :data="data[indextr].code">
                      {{ data[indextr].user_name }} / {{ data[indextr].user_phone }}
                    </vs-td>
                    <vs-td :data="data[indextr].code">
                      <p class="product-name font-medium truncate" :title="data[indextr].address">{{ data[indextr].address }}</p>
                    </vs-td>
                    <vs-td :data="data[indextr].code">
                      <feather-icon v-if="data[indextr].payment_type === 'cart'" icon="CreditCardIcon" svgClasses="mr-2 w-5 h-5 text-primary" />
                      <feather-icon v-else icon="DollarSignIcon" svgClasses="mr-2 w-5 h-5 text-primary" />
                      <span style="vertical-align: top">{{ data[indextr].total_price }}m</span>
                    </vs-td>
                    <vs-td :data="data[indextr].code">
                      <span>{{ new Date(data[indextr].createdAt).toLocaleString('ru-RU') }}</span>
                    </vs-td>
                    <vs-td :data="data[indextr].code">
                      <span>{{data[indextr].delivery_time}}</span>
                    </vs-td>
                    <vs-td class="whitespace-no-wrap">
                      <router-link :to="{name: 'orderInvoice', params: {id: data[indextr].uuid}}" class="text-dark pr-2">
                        <feather-icon icon="FileTextIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" />
                      </router-link>
                      <router-link :to="{name: 'orderShow', params: {id: data[indextr].uuid}}" class="text-dark">
                        <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" />
                      </router-link>
                      <!-- <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editData(tr)" /> -->
                      <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.id)" /> -->
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
// import analyticsData from './ui-elements/card/analyticsData.js'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import VxTimeline from '@/components/timeline/VxTimeline'

export default {
  data () {
    return {
      chartsReady: false,
      chart4Data: {},
      chart5Data: {},
      statistics: {},
      chart4Current: 'today',
      chart5Current: 'today',
      chart4Options: {
        chart: { type: 'bar', sparkline: { enabled: true }, toolbar: { show: false } },
        colors: ['rgba(115,103,240,0.15)', 'rgba(115,103,240,0.15)', 'rgba(115,103,240,0.15)', 'rgba(115,103,240,0.15)', 'rgba(115,103,240,0.15)', 'rgba(115,103,240,0.15)', 'rgba(115,103,240,0.15)'],
        plotOptions: { bar: { columnWidth: '45%', distributed: true, endingShape: 'rounded' } },
        tooltip: { x: { show: false } },
        series: [{ name: 'Orders', data: [0,  0,  0,  0,  0,  0, 0] }]
      },
      chart5Options: {
        chart: { type: 'bar', sparkline: { enabled: true }, toolbar: { show: false } },
        colors: ['rgba(115,103,240,0.15)', 'rgba(115,103,240,0.15)', 'rgba(115,103,240,0.15)', 'rgba(115,103,240,0.15)', 'rgba(115,103,240,0.15)', 'rgba(115,103,240,0.15)', 'rgba(115,103,240,0.15)'],
        plotOptions: { bar: { columnWidth: '45%', distributed: true, endingShape: 'rounded' } },
        tooltip: { x: { show: false } },
        series: [{ name: 'Orders', data: [0,  0,  0,  0,  0,  0, 0] }]
      },
      chartOptions: {
        today: { en: 'Last day', ru: 'Последний день', tm: 'Soňky gün' },
        this_week: { en: 'Last 7 days', ru: 'Последние 7 дней', tm: 'Soňky 7 gün' },
        this_month: { en: 'Last 30 days', ru: 'Последние 30 дней', tm: 'Soňky 30 gün' }
      },
      subscribersGained: {},
      revenueGenerated: {},
      ordersRecevied: {},

      supportTracker: {},
      salesRadar: {},
      // analyticsData,
      dispatchedOrders: [],
      isLoading: true
    }
  },
  components: {
    VueApexCharts,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    VxTimeline
  },
  async created () {
    await this.fetchData()
    this.chartsReady = true
    this.supportTracker = {
      analyticsData: {
        openTickets:163, 
        meta: { 'New Tickets': 29, 'Open Tickets': 63, 'Response Time':'1d' }}, 
      series: [83]
    }
    this.salesRadar = {'series':[{'name':'Visits', 'data':[90, 50, 86, 40, 100, 20]}, {'name':'Sales', 'data':[70, 75, 70, 76, 20, 85]}]}
    this.isLoading = false
  },
  computed: {
    chart4 () { return this.chart4Data[this.chart4Current] || {} },
    chart5 () { return this.chart5Data[this.chart5Current] || {} },
    chart4CurrentTitle () { return this.chartOptions[this.chart4Current][this.$i18n.locale] },
    chart5CurrentTitle () { return this.chartOptions[this.chart5Current][this.$i18n.locale] }
  },
  methods: {
    async fetchData () {
      await this.$http.get('/statistics/current', { params: { model: 0 } }).then(res => { if (res.status === 200) { this.chart4Data = res.data } })
      await this.$http.get('/statistics/current', { params: { model: 1 } }).then(res => { if (res.status === 200) { this.chart5Data = res.data } })
      await this.$http.get('/statistics').then(res => { this.statistics = res.data })
      await this.$http.get('/orders', { params: { limit: 10, status: 'pending' } }).then(res => { this.dispatchedOrders = res.data.data })
      const today = new Date()
      if (today.getDay() !== 0) {
        this.chart4Options.colors[today.getDay() - 1] = '#7367f0'
        this.chart5Options.colors[today.getDay() - 1] = '#7367f0'
      } else {
        this.chart4Options.colors[6] = '#7367f0'
        this.chart5Options.colors[6] = '#7367f0'
      }
      this.$store.dispatch('setPending', this.chart4Data.this_week.pending)
      await this.$http.get('/statistics/last-seven').then(res => {
        let i = 1
        while (i < 8) {
          if ((today.getDay() - i) > -1) { this.chart4Options.series[0].data[today.getDay() - i] = Number(res.data[i - 1]) } else {
            this.chart4Options.series[0].data[7 + (today.getDay() - i)] = Number(res.data[i - 1])
          } i++
        }
      })
      this.subscribersGained = {
        series: [{ name: 'Subscribers', data: this.statistics.users.map(el => el.all).reverse() }],
        analyticsData: { subscribers: this.statistics.count.users }
      }
      this.revenueGenerated = {
        series:[{ name:'Revenue', data: this.statistics.orders.map(el => el.income).reverse() }],
        analyticsData: { revenue: this.statistics.budget.orders }
      }
      this.ordersRecevied = {
        series:[{name:'Orders', data:this.statistics.orders.map(el => el.all).reverse() }],
        analyticsData: { orders: this.statistics.count.orders }
      }
      // const yesterday = new Date(today)
      // yesterday.setHours(0, 0, 0, 0)
      // let i = 1
      // while (i < 8) {
      //   yesterday.setDate(yesterday.getDate() - i)
      //   await this.$http.get('/statistics/income', { params: { model: 0, from: yesterday, until: yesterday } })
      //     .then(res => {
      //       if ((today.getDay() - i) > -1) { this.chart4Options.series[0].data[today.getDay() - i] = Number(res.data.stats) } else {
      //         this.chart4Options.series[0].data[7 + (today.getDay() - i)] = Number(res.data.stats)
      //       }
      //     })
      //   i++
      // }
    },
    change4Duration (e) { this.chart4Current = e },
    change5Duration (e) { this.chart5Current = e }
  }
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left,  .decore-right{
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
